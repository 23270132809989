import { SNAPDEALIST_APP_ID } from '@snapbrains/snapav/src/avantlink';

export const AppName = 'SnapDealist';
export const AppDescription = `${AppName} is a free tool for AvantLink affiliates that makes adding product deals to your website or blog simple.`;
export const AppEmail = 'support@snaplinker.com';
export const AppId = SNAPDEALIST_APP_ID;

export const GoogleAnalyticsId = null; // 'G-2DEQ4TYGY9' can be used for SnapSearcher but I'm turning it off until we get the accept cookies banner in place;

export const AppUrl = 'https://www.snapdealist.com';

// Rust based settings server
// export const SettingsApiUrl = 'https://settings.snapslinger.com';
// export const JwtAuthApiUrl = 'https://settings.snapslinger.com';

// export const SettingsApiUrl = 'http://localhost:8080';
// export const JwtAuthApiUrl = 'http://localhost:8080';

// Cloudflare worker based settings
// Production
export const SettingsApiUrl = 'https://settings.snapdealist.com';
export const JwtAuthApiUrl = 'https://settings.snapdealist.com';

// export const SettingsApiUrl = 'http://127.0.0.1:8787';
// export const JwtAuthApiUrl = 'http://127.0.0.1:8787';

export const SignUpFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSf5CygL8sqj6vkm9y9iFEklVgG0s53SL2bUP5lYb5Xl3OO7aw/viewform?embedded=true&entry.1721758524=I+agree+to+receive+SnapDealist+updates+via+email&entry.1721758524=I+wish+to+receive+updates+about+other+Snap+related+tools+(ie+SnapLinker,+SnapSearcher)';
