import React from 'react';
import PropTypes from 'prop-types';

import GlobalStyles from '@snapbrains/snaparts/src/styles/global_styles';
import Deals from '@snapbrains/snaparts/src/components/search/deals';

export default function Home(props) {
  const { settings } = props;
  return (
    <div className="snapbase-widget">
      <GlobalStyles settings={settings} containerCssClass=".snapbase-widget" />
      <Deals settings={settings} />
    </div>
  );
}

Home.propTypes = {
  settings: PropTypes.shape({
    forAffiliate: PropTypes.bool,
    hideSort: PropTypes.bool,
    queryParam: PropTypes.string,
    searchPriceMinimum: PropTypes.number,
    searchPriceMaximum: PropTypes.number,
    hideSearch: PropTypes.bool,
    placeholder: PropTypes.string,
    slider: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    allowFiltering: PropTypes.bool,
    showOnSaleOnly: PropTypes.bool,
  }),
};

Home.defaultProps = {
  settings: {},
};
